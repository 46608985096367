// src/BlogPost3.tsx
import React from 'react';

const BlogPost3: React.FC = () => {
  return (
    <div className="container mx-auto px-6 py-12">
      <h1 className="text-4xl font-bold mb-4">Titre de l'article 3</h1>
      <p className="text-lg mb-8">Contenu de l'article 3...</p>
      {/* Ajoutez le contenu de l'article ici */}
    </div>
  );
};

export default BlogPost3;
